<script setup lang="ts">
import CategoryGrid from '@/components/Category/CategoryGrid.vue'
import BrandGrid from '@/components/Brand/BrandGrid.vue'
import GoodsGrid from '@/components/Goods/GoodsGrid.vue'
import Tags from '@/components/Tags/Tags.vue'
import type { SearchData, GoodsItem, CategoryItem, TagItem, BrandItem } from '@/types/SearchData'
import { onMounted, ref, watch, provide, computed } from 'vue'
import { useSearchData } from '@/utils/useSearchData'
const goods = ref<GoodsItem[]>([])
const categories = ref<CategoryItem[]>([])
const tags = ref<TagItem[]>([]);
const brands = ref<BrandItem[]>([])
const searchData = ref<SearchData | null>(null)
const query = ref('')
let timeoutId: ReturnType<typeof setTimeout> | null = null

onMounted(() => {
	const inputElement = document.getElementById('title-search-input') as HTMLInputElement
	const inputId = 'title-search-input'

	inputElement.addEventListener('input', event => {
		query.value = (event.target as HTMLInputElement).value
	})

	inputElement.addEventListener('keyup', event => {
		if (event.code === 'Escape') {
			const searchApp = document.getElementById('search-app') as HTMLInputElement;
			const body = document.body;
			searchApp.classList.add('hidden');
			body.classList.remove('overflow-hidden');
		}
	})

	watch(query, async newQuery => {
		if (timeoutId) {
			clearTimeout(timeoutId)
		}

		timeoutId = setTimeout(async () => {
			const data = await useSearchData(inputId, newQuery)
			if (data) {
				searchData.value = data
				goods.value = data.searchData
					.filter(item => item.type === 'goods')
					.flatMap(item => item.list as GoodsItem[])

				categories.value = data.searchData
					.filter(item => item.type === 'category')
					.flatMap(item => item.list as CategoryItem[])
                tags.value = data.searchData
                .filter((item) => item.type === "tag")
                .flatMap((item) => item.list as TagItem[]);
                brands.value = data.searchData
                .filter((item) => item.type === "brand")
                .flatMap((item) => item.list as BrandItem[]);
			}
		}, 1000)
	})
})

provide('goods', goods)
provide('categories', categories)
provide('tags', tags)
provide('brands', brands)

watch(searchData, (newSearchData) => {
	const inputSearch = document.getElementById('title-search-input') as HTMLInputElement
	const urlSearch = document.querySelector('.search__allGoods') as HTMLAnchorElement

	if (newSearchData && newSearchData.urlAll) {
		const searchLinkDesk = document.getElementById('link-desk')
		const searchLinkMobile = document.getElementById('link-mobile')

		if (searchLinkDesk) {
			searchLinkDesk.setAttribute('href', newSearchData.urlAll)
		}

		if (searchLinkMobile) {
			searchLinkMobile.setAttribute('href', newSearchData.urlAll)
		}

		inputSearch.addEventListener('keyup', event => {
			if (event.code === 'Enter') {
				urlSearch.click()
			}
		})
	}
})
</script>

<template>
	<div v-if="searchData?.meta.existCatalogs" class="search">
		<div class="search__wrapper">
			<Tags  v-if="tags.length > 0 && searchData?.meta.existTags"/>
			<CategoryGrid v-if="categories.length > 0"/>
			<BrandGrid v-if="brands.length > 0 && searchData?.meta.existBrands" />
			<GoodsGrid v-if="goods.length > 0"/>
		</div>
		<a v-if="searchData?.urlAll" :href="searchData.urlAll" class="search__allGoods">Показать все товары</a>
	</div>
</template>

<style scoped lang="scss">
.search {
	position: relative;
	box-shadow: rgb(204, 204, 204) 1px 4px 6px -2px;
	width: 100%;
	background: rgb(255, 255, 255);
	margin: 0px;
	&__wrapper {
		margin: 0 auto;
		max-width: 800px;
		height: auto;
		max-height: 56vh;
		padding: 10px;
		overflow: auto;
		scrollbar-width: thin;
		scrollbar-color: rgb(221, 221, 221) #f0f0f0;
		@media screen and (min-width: 768px) {
			padding: 15px 4px;
			padding-right: 15px;
		}
		&::-webkit-scrollbar {
			width: 10px;
		}

		&::-webkit-scrollbar-track {
			background: #f0f0f0;
			border-radius: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background-color: #f0f0f0;
			border-radius: 10px;
			border: 2px solid #f0f0f0;
		}

		&::-webkit-scrollbar-thumb:hover {
			background-color: #ff5490;
		}
	}
	&__allGoods {
		font-weight: normal;
		width: 100%;
		cursor: pointer;
		display: flex;
		padding: 0.5em 0;
		background: #ffffff;
		align-items: center;
		justify-content: center;
	}
}
#app {
	font-family: Verdana, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #000;
}
</style>
